import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';
import moment from "moment";
import css from './ListingPage.module.css';
const MODAL_BREAKPOINT = 768;
const SectionDetailsMaybe = props => {
  const { publicData, metadata = {}, listingConfig, intl } = props;
  const { listingFields } = listingConfig || {};

  const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
  if (!publicData || !listingConfig) {
    return null;
  }

  const { endDateString, endTime, offer, slot, pickupEnabled = false, pickupDateString = '', pickupStartTime = '', pickupEndTime = '', location=null,DeliveryInstructions = ''
} = publicData || {};
const  dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'long', year:'numeric' };
const endDateTime = endDateString && endTime ? new Date(`${endDateString} ${endTime}`).getTime() : null;
const currentDateTime = Date.now();
const isDealActive = offer && (currentDateTime <= endDateTime);
const hasPickupDetails = pickupEnabled && pickupDateString.length && pickupStartTime.length && pickupEndTime.length;
const endDealDateFormatted = endDateString?intl.formatDate(moment(endDateString).toDate(), dateFormattingOptions):null;
const pickupendDateTime = pickupDateString && pickupEndTime ? new Date(`${pickupDateString} ${pickupEndTime}`).getTime() : null;

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, includeForListingTypes, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType =
      includeForListingTypes == null || includeForListingTypes.includes(listingType);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
        ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
        : schemaType === 'long'
        ? filteredConfigs.concat({ key, value, label })
        : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFields.reduce(pickListingFields, []);

  return existingListingFields.length > 0 ? (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>

      {(isDealActive) && isMobileLayout && (
        <>
          <label className={css.dealHeading}>  <FormattedMessage id="ListingPage.dealHeading" /></label>
          <p className={css.dealDescription}>
          {parseInt(slot) === 0 ? intl.formatMessage({ id: 'ProductOrderForm.goalreached' }) : intl.formatMessage({ id: 'ProductOrderForm.Goalitemneeded' }, { slot,  endDealDateFormatted, endTime}) }
          </p>
        </>
      )}
      <ul className={css.details}>
        {existingListingFields.map(detail => (
          <li key={detail.key} className={css.detailsRow}>
            <span className={css.detailLabel}>{detail.label}</span>
            <span>{detail.value}</span>
          </li>
        ))}
      </ul>

      {hasPickupDetails && isMobileLayout ? <>
        <div className={css.pickupLabel}>{intl.formatMessage({
          id: 'ProductOrderForm.pickupDetails',
        })}</div>
        <ul className={css.pickupDetails}>
          <li><span className={css.detailBold}>{intl.formatMessage({
            id: 'ProductOrderForm.pickupDate',
          })}:</span>{intl.formatDate(moment(pickupDateString).toDate(), dateFormattingOptions)}</li>
          <li><span className={css.detailBold}>{intl.formatMessage({
            id: 'ProductOrderForm.pickupStartTime',
          })}:</span> {pickupStartTime}</li>
          <li><span className={css.detailBold}>{intl.formatMessage({
            id: 'ProductOrderForm.pickupEndTime',
          })}:</span> {pickupEndTime}</li>
        </ul>
      </>
        : null}

      {location && isMobileLayout && location?.address ? <>
        <div className={css.pickupLabel}>{intl.formatMessage({
          id: 'ProductOrderForm.location',
        })}</div>
        <ul className={css.pickupDetails}>
          <li><span className={css.detailBold}>{intl.formatMessage({
            id: 'ProductOrderForm.address',
          })}:</span> {location?.address}</li>
          {location?.building && location?.building.length ? <li><span className={css.detailBold}>{intl.formatMessage({
            id: 'ProductOrderForm.building',
          })}:</span> {location?.building}</li> : null}
          {DeliveryInstructions && DeliveryInstructions.length ? <li><span className={css.detailBold}>{intl.formatMessage({
            id: 'ProductOrderForm.DeliveryInstructions',
          })}:</span> {DeliveryInstructions}</li> : null}
        </ul>
      </>
        : null}
    </div>
  ) : null;
};

export default SectionDetailsMaybe;
